import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { mobile, smLaptop, useQuery } from 'styles/breakpoints';
import { LoadingPage, PrimaryButton, Reviews, Seo } from 'components';
import Header, { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from 'components/Header';
import { useRouter } from 'apis/history';
import { usePageView, useQuizData } from 'utils/hooks';
import Container from 'pages/start/checkout/components/Container';
import Countdown from 'components/Countdown';
import Disclaimer from 'components/Disclaimer';
import PeopleAskUs from 'components/PeopleAskUs';
import HeadingSection from 'components/HeadingSection';
import Description from 'pages/start/results/components/Description';
import IconList from 'pages/start/results/components/IconList';
import { Product } from 'types/product';
import { Analytics } from 'apis/Analytics';
import { updateSelectedPlan } from 'state/user/effects';
import { AppState } from 'state/types';
import DiscountSubscription from 'pages/start/discount/components/DiscountSubscription';

const PeopleAskUsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGreen};
  padding: 4rem 0;
  width: 100%;
  @media ${smLaptop} {
    padding: 2rem 0;
  }
`;

const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  padding: 0.625rem 1.5rem;
  min-width: 10.22rem;
  max-width: 15rem;
  @media ${mobile} {
    min-width: 7.06rem;
  }
`;

const Discount = () => {
  const [productsVariant, setProductsVariant] = useState<{
    oldProduct: Product;
    newProduct: Product;
  } | null>(null);
  const [productSlugs, setProductSlugs] = useState<{
    oldProductSlug: string;
    newProductSlug: string;
  } | null>(null);

  const { isMobile } = useQuery();

  const data = useQuizData('discount');

  const dispatch = useDispatch();

  const { goToPayments } = useRouter();

  usePageView();

  const subscriptionContainerRef = useRef<HTMLDivElement | null>(null);

  const products = useSelector(
    (state: AppState) => state.funnel.products,
  ) as Product[];

  const handleScrollToSubscriptions = () => {
    const offsetTop = subscriptionContainerRef.current?.offsetTop;
    if (offsetTop === undefined) return;
    let headerHight = 0;
    if (isMobile) {
      headerHight = HEADER_HEIGHT_MOBILE * 16;
    } else {
      headerHight = HEADER_HEIGHT * 16;
    }
    window.scrollTo({ top: offsetTop - headerHight, behavior: 'smooth' });
  };

  useEffect(() => {
    if (productSlugs && products.length) {
      const oldProductIndex = products.findIndex(
        product => product.key === productSlugs.oldProductSlug,
      );
      const newProductIndex = products.findIndex(
        product => product.key === productSlugs.newProductSlug,
      );
      const oldProduct = products[oldProductIndex];
      const newProduct = products[newProductIndex];
      setProductsVariant({ oldProduct, newProduct });
    }
  }, [products, productSlugs]);

  useEffect(() => {
    if (data?.oldProduct && data?.newProduct) {
      setProductSlugs({
        oldProductSlug: data.oldProduct,
        newProductSlug: data.newProduct,
      });
    }
  }, [data]);

  const handleContinue = (product: Product) => {
    Analytics.trackCTAButton(location.pathname);

    dispatch(updateSelectedPlan(product));
    goToPayments({ discount: false });
  };

  const renderSection = (item: any, index: number) => {
    const key = `${item.type}-${index}`;
    switch (item.type) {
      case 'headingSection': {
        return <HeadingSection key={key} {...item} />;
      }
      case 'countdownSection': {
        return <Countdown key={key} {...item} perDay={''} />;
      }
      case 'appBenefitsSection': {
        return (
          <Container key={key} top={4} bottom={4}>
            <Description {...item} />
          </Container>
        );
      }
      case 'section4': {
        return (
          <Description key={key} title={item.title} subtitle={item.subtitle}>
            <IconList data={item.list} />
          </Description>
        );
      }
      case 'reviewsSection': {
        return (
          <Container key={key} top={4} bottom={4}>
            <Reviews {...item} />
          </Container>
        );
      }
      case 'peopleAskUsSection': {
        return (
          <PeopleAskUsContainer key={key}>
            <Container widthVariant="small" top={0} bottom={0}>
              <PeopleAskUs {...item} />
            </Container>
          </PeopleAskUsContainer>
        );
      }
      case 'disclaimerSection': {
        return (
          <Container key={key}>
            <Disclaimer isWithPaymentMethods={false} {...item} />;
          </Container>
        );
      }
      case 'discountSubscriptionSection': {
        return (
          <div ref={subscriptionContainerRef} key={key}>
            <DiscountSubscription
              {...item}
              products={productsVariant}
              onGetMyAccess={handleContinue}
            />
          </div>
        );
      }
    }
  };

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo title="Effecto | Discount" />
      <Header
        logoVariant="left"
        RightComponent={
          isMobile ? null : (
            <Button
              onClick={handleScrollToSubscriptions}
              fontSize={isMobile ? 0.75 : undefined}
              minHeight={isMobile ? 2.5 : undefined}
            >
              {data?.navBarBtnTitle}
            </Button>
          )
        }
      />
      {data?.sections?.map(renderSection)}
    </>
  );
};

export default Discount;
